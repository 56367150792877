import {
  IOperacaoAntecipacaoDto,
  ITotalOperationsDto,
} from '../../../global/dataTransferObjects/operacoesAntecipacao';

export interface IPageState {
  loading: boolean;
  totalOperations: ITotalOperationsDto;
  records: IOperacaoAntecipacaoDto[];
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_ALL_OPERACOES_ANTECIPACAO = 'GET_ALL_OPERACOES_ANTECIPACAO',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
