import { IOperacoesAntecipacaoService } from '../@types/services';
import { createApi } from '../providers/factories';
import antecipacaoService from './antecipacaoService';

const createOperacoesAntecipacaoService = (): IOperacoesAntecipacaoService => {
  const apiProvider = createApi();
  return antecipacaoService(apiProvider);
};

export default createOperacoesAntecipacaoService;
