import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { IOperacaoAntecipacaoDto } from '../../../global/dataTransferObjects/operacoesAntecipacao';
import { IPagedOperacaoAntecipacaoResult } from '../../../global/generics';
import createOperacoesAntecipacaoService from '../../../services/antecipacao';
import { IFilterOperacoesAntecipacaoProps } from '../components/FilterForm/types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onGetAllOperacoesAntecipacao = (
  OperacoesAntecipacao: IPagedOperacaoAntecipacaoResult<IOperacaoAntecipacaoDto>,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_OPERACOES_ANTECIPACAO,
  payload: {
    records: OperacoesAntecipacao.records,
    totalOperations: OperacoesAntecipacao.totalOperations,
  },
});

export const getAllOperacoesAntecipacao = (
  offset: number,
  pageSize: number,
  filter?: IFilterOperacoesAntecipacaoProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const operacoesAntecipacaoService = createOperacoesAntecipacaoService();
  onUpdateLoadingStatus(true)(dispatch);

  try {
    const result = await operacoesAntecipacaoService.getAllOperacoesAntecipacao(offset, pageSize, filter);
    if (result.status !== 200) {
      onUpdateLoadingStatus(false)(dispatch);
      toast.error(result.message);
      return;
    }

    onGetAllOperacoesAntecipacao(result.response)(dispatch);
  } catch (error) {
    toast.error('Erro ao carregar dados');
  } finally {
    onUpdateLoadingStatus(false)(dispatch);
  }
};
