import {
  IOperacaoAntecipacaoDto,
  IFilterOperacoesAntecipacaoProps,
} from '../../global/dataTransferObjects/operacoesAntecipacao';
import { IPagedAnticipationOperationResult } from '../../global/generics';
import { IOperacoesAntecipacaoService } from '../@types/services';
import { HttpMethod, IApiResponse, ITecpayApi } from '../providers/types';

const OperacoesAntecipacaoService = (apiProvider: ITecpayApi): IOperacoesAntecipacaoService => {
  const getAllOperacoesAntecipacao = async (
    offSet:number,
    pageSize: number,
    filter?: IFilterOperacoesAntecipacaoProps,
  ): Promise<IApiResponse<IPagedAnticipationOperationResult<IOperacaoAntecipacaoDto>>> => {
    const status = filter?.status?.value || null;
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const response = await apiProvider
      .createRequest<IPagedAnticipationOperationResult<IOperacaoAntecipacaoDto>>({ url: 'anticipation-operation' })
      .withSearch({
        offSet,
        pageSize,
        status,
        startDate,
        finishDate,
      })
      .sendAsync();

    if (response.status !== 200) {
      throw new Error('Falha ao buscar operações de antecipação.');
    }

    return response;
  };

  const fazerPagamentoOperacoesAntecipacao = async (): Promise<void> => {
    const response = await apiProvider
      .createRequest({ url: 'anticipation-operation/make-payment' })
      .withMethod(HttpMethod.POST)
      .sendAsync();

    if (response.status !== 200) {
      throw new Error('Erro ao realizar pagamento.');
    }
  };

  return ({
    getAllOperacoesAntecipacao,
    fazerPagamentoOperacoesAntecipacao,
  });
};
export default OperacoesAntecipacaoService;
