/* eslint-disable max-len */
import { IOperacaoAntecipacaoDto } from '../../../global/dataTransferObjects/operacoesAntecipacao';
import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  totalOperations: {
    numberOfInstallments: 0,
    grossAmount: 0,
    numberOfInstallmentsAccepted: 0,
    numberOfInstallmentsRejected: 0,
    paymentAmount: 0,
    anticipationAmount: 0,
    numRows: 0,
  },
  records: [] as IOperacaoAntecipacaoDto[],
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.GET_ALL_OPERACOES_ANTECIPACAO:
      return {
        ...state,
        loading: false,
        records: action.payload?.records ?? [],
        totalOperations: action.payload?.totalOperations ?? state.totalOperations,
      };
    default:
      return { ...state };
  }
};
