import React, {
  useReducer,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { toast } from 'react-toastify';
import Table, { TableColumn, TableSum, TableSumCell } from '../../components/Table';
import { Reducer, InitialState } from './actions/reducer';
import { createFormatProvider } from '../../services/providers/factories';
import {
  getAllOperacoesAntecipacao,
} from './actions';
import {
  Container,
  TableContainer,
} from './styles';
import { T2 } from '../../styles/titles';
import FilterForm, {} from './components/FilterForm';
import { IFilterOperacoesAntecipacaoProps } from './components/FilterForm/types';
import LoadingOverlay from '../../components/LoadingOverlay';
import createOperacoesAntecipacaoService from '../../services/antecipacao';

const OperacoesAntecipacao: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<IFilterOperacoesAntecipacaoProps>();
  const operacoesAntecipacaoService = createOperacoesAntecipacaoService();

  const fetchAllOperacoesAntecipacao = useCallback(() => getAllOperacoesAntecipacao(offSet, pageSize)(dispatch), []);
  useEffect(() => {
    fetchAllOperacoesAntecipacao();
  }, [fetchAllOperacoesAntecipacao]);

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const fetchAllOperacoesAntecipacaoPagination = (initialPage: number, finalPage :number) => {
    getAllOperacoesAntecipacao(initialPage, finalPage, filterOrder)(dispatch);
  }

  const handleDateFilter = async (filter: IFilterOperacoesAntecipacaoProps) => {
    setFilterOrder(filter);
    getAllOperacoesAntecipacao(offSet, pageSize, filter)(dispatch);
  };

  const handlePayment = async () => {
    try {
      await operacoesAntecipacaoService.fazerPagamentoOperacoesAntecipacao();
      toast.success('Pagamento realizado com sucesso!');
    } catch (error) {
      toast.error('Erro ao realizar pagamento.');
    }
  };

  const handleStatus = (status: string) => {
    switch (status) {
      case 'Approved':
        return 'Aprovada pela Mesa de Operações';
      case 'Rejected':
        return 'Rejeitada pela Mesa de Operações';
      case 'Payed':
        return 'Pago pela Antecipadora';
      case 'Received':
        return 'Recebido pela Empresa Padrão';
      case 'Closed':
        return 'Fechada';
      default:
        return 'N/A'
    }
  }

  useEffect(() => {
    if (currentPage > 1) {
      fetchAllOperacoesAntecipacaoPagination(pageSize * (currentPage - 1), (currentPage * pageSize))
    }
    if (currentPage === 1) {
      fetchAllOperacoesAntecipacaoPagination(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  const Operations = state?.records?.map((item) => ({
    ...item,
  }));
  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Operações de Antecipação</T2>
      <FilterForm
        onFormSubmit={handleDateFilter}
        OnPaymentSubmit={() => handlePayment()}
      />
      <TableContainer>
        <Table
          dataSource={Operations}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.totalOperations.numRows,
              onChange: (pageNum: React.SetStateAction<number>) => {
                setCurrentPage(pageNum);
              },
            }
          }
          summary={() => (
            <TableSum fixed>
              <TableSum.Row>
                <TableSumCell index={0} />
                <TableSumCell index={1} />
                <TableSumCell index={2} />
                <TableSumCell index={3} />
                <TableSumCell index={4} align="left"><b>{format(state?.totalOperations.paymentAmount)}</b></TableSumCell>
                <TableSumCell
                  index={5}
                  align="left"
                >
                  <b>{format(state?.totalOperations?.anticipationAmount)}</b>
                </TableSumCell>
                <TableSumCell index={6} />
              </TableSum.Row>
              <TableSum.Row>
                <TableSumCell index={0} />
                <TableSumCell index={1} />
                <TableSumCell index={2} />
                <TableSumCell index={3} />
                <TableSumCell index={4}><b>Total Valor Bruto</b></TableSumCell>
                <TableSumCell index={5}><b>Total Valor Líquido</b></TableSumCell>
                <TableSumCell index={6} />
              </TableSum.Row>
            </TableSum>
          )}
        >
          <TableColumn title="Id" dataIndex="id" key="id" width={200} />
          <TableColumn title="Data" dataIndex="created" key="created" render={formattedDate} width={200} />
          <TableColumn title="Antecipadora" dataIndex="anticipationBusinessName" key="anticipationBusinessName" width={250} />
          <TableColumn title="Qtd" dataIndex="numberOfInstallments" key="numberOfInstallments" width={250} />
          <TableColumn title="Bruto" dataIndex="paymentAmount" key="paymentAmount" render={format} />
          <TableColumn title="Liquido" dataIndex="anticipationAmount" key="anticipationAmount" render={format} />
          <TableColumn title="Status" dataIndex="status" key="status" render={handleStatus} />
        </Table>
      </TableContainer>
    </Container>
  );
};

export default OperacoesAntecipacao;
