import React from 'react';
import { withFormik } from 'formik';
import { Row, Col, Popconfirm } from 'antd';
import DatePickerField from '../../../../components/DatePicker';
import { FormProps, IFilterDate, IFilterOperacoesAntecipacaoProps } from './types';
import {
  DateView,
  Button,
  ResetButton,
  Form,
  Label,
} from './styles';
import { ISelectValue } from '../../../../global/types';
import SelectField from '../../../../components/Select';

const options: ISelectValue[] = [
  { label: 'Operação Solicitada', value: 'Asked' },
  { label: 'Aprovada pela Mesa de Operações', value: 'Approved' },
  { label: 'Rejeitada pela Mesa de Operações', value: 'Rejected' },
  { label: 'Pago pela Antecipadora', value: 'Payed' },
  { label: 'Recebido pela Empresa Padrão', value: 'Received' },
  { label: 'Fechada', value: 'Closed' },
]

const FilterForm: React.FC<FormProps> = (props: FormProps) => {
  const { handleSubmit, resetForm, OnPaymentSubmit } = props;
  const resetFormOperacoesTransacao = async () => {
    resetForm();
    await props.onFormSubmit({
      status: null,
    });
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row
          gutter={16}
        >
          <Col span={6}>
            <SelectField
              name="status"
              placeholder=""
              label="Status"
              options={options}
            />
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="startDate"
                placeholder="Data inicio"
                label="Data Inicio"
              />
            </DateView>
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="finishDate"
                placeholder="Data fim"
                label="Data fim"
              />
            </DateView>
          </Col>
        </Row>

        <Row
          gutter={16}
        >
          <Col span={8} />
          <Col span={10} />
          <Col span={3}>
            <ResetButton onClick={() => resetFormOperacoesTransacao()}>
              Limpar filtros
            </ResetButton>
          </Col>
          <Col span={3}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={20} />

          <Popconfirm
            title="Essa operação não pode ser desfeita, deseja enviar remessa para pagamento?"
            cancelText="Cancelar"
            okText="Enviar"
            onConfirm={() => OnPaymentSubmit()}
          >
            <Col span={4}>
              <Button>
                Enviar remessa SFTP
              </Button>
            </Col>
          </Popconfirm>
        </Row>
      </Form>
    </>
  )
}
export default withFormik<IFilterDate, IFilterOperacoesAntecipacaoProps>({
  mapPropsToValues: (): IFilterOperacoesAntecipacaoProps => ({
    status: null,
    startDate: '',
    finishDate: '',
  }),
  handleSubmit: (values, { props }) => {
    const formattedValues = {
      ...values,
    };
    props.onFormSubmit(formattedValues);
  },
})(FilterForm);
